@forward './main';

@forward "@material/layout-grid/variables";
@forward ".@material/layout-grid/mixins";
p, div, li,td,tr,span{
    font-family: 'Poppins', sans-serif;
}
 h1,h2,h3,h4,h5,h6{
    font-family: 'Poppins', sans-serif;
    font-weight: 800;
 }
 .pdLR{
    &.p1{
       padding: 0 .25rem;
    }
    &.p2{
       padding: 0 .5rem;
    }
    &.p3{
       padding: 0 .75rem;
    }
    &.p4{
       padding: 0 1rem;
    }
}

.mt{
    &.m1{
        margin-top: .25rem;
    }
    &.m2{
        margin-top: .5rem;
    }
    &.m3{
        margin-top: .75rem;
    }
    &.m4{
        margin-top: 1rem;
    }
}
.mr{
    &.m1{
        margin-right: .25rem;
    }
    &.m2{
        margin-right: .5rem;
    }
    &.m3{
        margin-right: .75rem;
    }
    &.m4{
        margin-right: 1rem;
    }
}

.txt-center{
    text-align: center;
}

.section{
    width: 100%;
}

.w-100{
    width:100%;
}