@use '@rmwc/avatar/avatar';
@use '@rmwc/icon/icon';
@use '@material/layout-grid/dist/mdc.layout-grid.css';
@use '@rmwc/typography/typography';
p, div, li,td,tr,span{
    font-family: 'Poppins', sans-serif;
}
 h1,h2,h3,h4,h5,h6{
    font-family: 'Poppins', sans-serif;
    font-weight: 800;
 }

 .pdLR{
    &.p1{
       padding: 0;
    }
    &.p2{
       padding: 0;
    }
    &.p3{
       padding: 0;
    }
    &.p4{
       padding: 0;
    }
}

.mt{
    &.m1{
        margin-top: .25rem;
    }
    &.m2{
        margin-top: .5rem;
    }
    &.m3{
        margin-top: .75rem;
    }
    &.m4{
        margin-top: 1rem;
    }
}

.mr{
    &.m1{
        margin-right: .25rem;
    }
    &.m2{
        margin-right: .5rem;
    }
    &.m3{
        margin-right: .75rem;
    }
    &.m4{
        margin-right: 1rem;
    }
}
.txt-center{
    text-align: center;
}

.section{
    width: 100%;
}

.w-100{
    width:100%;
}

.innerSpace h1{
    color:#fff;
}

.innerSpace ._spectrum-ActionButton_7a745{
    color:#a2a2a2;
    svg{
        fill:#a2a2a2;
    }

    :hover{
        color:#fff;
        svg{
            fill:#fff;
        }
    }

}
.innerSpace{
    color:#fff;
}
.innerSpace ._spectrum-FieldLabel_36b9b{
    color:#fff;
}
.innerSpace ._spectrum-Button_7a745, .threeDataDialBox ._spectrum-Button_7a745{
    border:none;
    color:#fff;
    font-family: 'Poppins', sans-serif;
    background: #343434;
    border-radius:2.25rem;
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);

    :hover{
        background:#080808;
        color:#a2a2a2;
    }
}
.innerSpace ._spectrum-ButtonGroup_25328 ._spectrum-ActionButton_7a745{
    background: rbga(250,250,250,1);
    border-radius:2.25rem;
    color:#000;
    border:none;
    border-radius:2.25rem;
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}
.innerSpace ._spectrum-ButtonGroup_25328 ._spectrum-ActionButton_7a745 svg{
    fill:#000;
}

.innerSpace ._spectrum-ButtonGroup_25328 ._spectrum-ActionButton_7a745:hover {
    background:#737171;
    color:#fff;
}
.innerSpace ._spectrum-ButtonGroup_25328 ._spectrum-ActionButton_7a745:hover ._spectrum-ActionButton-label_7a745:hover{
    color:#fff;
}
.innerSpace ._spectrum-ButtonGroup_25328 ._spectrum-ActionButton_7a745:hover svg{
    fill:#fff;
}

#cal-text {
    margin-right: '15px';
    background-color: blue;
    border-color: white;
}

.innerSpace input:-internal-autofill-selected {
    background:#a2a2a2;
    background-color:#a2a2a2!important;
    color:#038C7F!important;
}
.innerSpace input::placeholder, .innerSpace input[placeholder]{
    background:#a2a2a2;
    background-color:#a2a2a2!important;
    color:#038C7F!important;
}

.innerSpace ._spectrum-Button_7a745:hover, .threeDataDialBox ._spectrum-Button_7a745:hover{
    background:#080808;
    color:#a2a2a2;
}
._spectrum-Textfield--quiet_61339 ._spectrum-Textfield-input_61339._is-focused_61339, ._spectrum-Textfield--quiet_61339 ._spectrum-Textfield-input_61339:focus,
[dir=ltr] ._spectrum-Search_973c6._is-quiet_973c6 ._spectrum-Search-input_973c6
{
    color:#fff;
}

.threeDataDialBox button._spectrum-ActionButton_7a745{
    margin-top: 1.5rem;
    margin-left: 1.5rem;
    border:none;
    color:#fff;
    font-family: 'Poppins', sans-serif;
    background: #343434;
    border-radius:2.25rem;
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);

    :hover{
        background:#080808;
        color:#a2a2a2;
    }
}
.threeDataDialBox button._spectrum-ActionButton_7a745:hover{
    background:#080808;
    color:#a2a2a2;
}

.innerSpace ._spectrum-ButtonGroup_25328 ._spectrum-ActionButton_7a745.BoxButton{
    width: 350px;
    height: 300px;
    background: #343434;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px; 
    border-radius: 20px; 
    border: transparent; 
    padding: 10px;
    color:#a09c9c;
    font-family: 'Poppins', sans-serif;
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}
.innerSpace ._spectrum-ButtonGroup_25328 ._spectrum-ActionButton_7a745.BoxButton svg{
    fill:#a09c9c;
}
.innerSpace ._spectrum-ButtonGroup_25328 ._spectrum-ActionButton_7a745.BoxButton:hover svg,
.innerSpace ._spectrum-ButtonGroup_25328 ._spectrum-ActionButton_7a745.BoxButton:hover,
.innerSpace ._spectrum-ButtonGroup_25328 ._spectrum-ActionButton_7a745.BoxButton:hover ._spectrum-ActionButton-label_7a745:hover{
    background: #000;
    color:#fff;
    fill: #fff;
}