.testcs {
  --spectrum-global-color-status: Verified;
  --spectrum-global-color-version: 5.0.1;
  --spectrum-global-color-celery-400: rgb(68, 181, 86);
  --spectrum-global-color-celery-500: rgb(61, 167, 78);
  --spectrum-global-color-celery-600: rgb(55, 153, 71);
  --spectrum-global-color-celery-700: rgb(49, 139, 64);
  --spectrum-global-color-chartreuse-400: rgb(133, 208, 68);
  --spectrum-global-color-chartreuse-500: rgb(124, 195, 63);
  --spectrum-global-color-chartreuse-600: rgb(115, 181, 58);
  --spectrum-global-color-chartreuse-700: rgb(106, 168, 52);
  --spectrum-global-color-yellow-400: rgb(223, 191, 0);
  --spectrum-global-color-yellow-500: rgb(210, 178, 0);
  --spectrum-global-color-yellow-600: rgb(196, 166, 0);
  --spectrum-global-color-yellow-700: rgb(183, 153, 0);
  --spectrum-global-color-magenta-400: rgb(216, 55, 144);
  --spectrum-global-color-magenta-500: rgb(206, 39, 131);
  --spectrum-global-color-magenta-600: rgb(188, 28, 116);
  --spectrum-global-color-magenta-700: rgb(174, 14, 102);
  --spectrum-global-color-fuchsia-400: rgb(192, 56, 204);
  --spectrum-global-color-fuchsia-500: rgb(177, 48, 189);
  --spectrum-global-color-fuchsia-600: rgb(162, 40, 173);
  --spectrum-global-color-fuchsia-700: rgb(147, 33, 158);
  --spectrum-global-color-purple-400: rgb(146, 86, 217);
  --spectrum-global-color-purple-500: rgb(134, 76, 204);
  --spectrum-global-color-purple-600: rgb(122, 66, 191);
  --spectrum-global-color-purple-700: rgb(111, 56, 177);
  --spectrum-global-color-indigo-400: rgb(103, 103, 236);
  --spectrum-global-color-indigo-500: rgb(92, 92, 224);
  --spectrum-global-color-indigo-600: rgb(81, 81, 211);
  --spectrum-global-color-indigo-700: rgb(70, 70, 198);
  --spectrum-global-color-seafoam-400: rgb(27, 149, 154);
  --spectrum-global-color-seafoam-500: rgb(22, 135, 140);
  --spectrum-global-color-seafoam-600: rgb(15, 121, 125);
  --spectrum-global-color-seafoam-700: rgb(9, 108, 111);
  --spectrum-global-color-red-400: rgb(227, 72, 80);
  --spectrum-global-color-red-500: rgb(215, 55, 63);
  --spectrum-global-color-red-600: rgb(201, 37, 45);
  --spectrum-global-color-red-700: rgb(187, 18, 26);
  --spectrum-global-color-orange-400: rgb(230, 134, 25);
  --spectrum-global-color-orange-500: rgb(218, 123, 17);
  --spectrum-global-color-orange-600: rgb(203, 111, 16);
  --spectrum-global-color-orange-700: rgb(189, 100, 13);
  --spectrum-global-color-green-400: rgb(45, 157, 120);
  --spectrum-global-color-green-500: rgb(38, 142, 108);
  --spectrum-global-color-green-600: rgb(18, 128, 92);
  --spectrum-global-color-green-700: rgb(16, 113, 84);
  --spectrum-global-color-blue-400: #038C7F;
  --spectrum-global-color-blue-500: #038C7F;
  --spectrum-global-color-blue-600: rgb(13, 102, 208);
  --spectrum-global-color-blue-700: rgb(9, 90, 186);
  --spectrum-global-color-gray-50: rgb(252, 245, 245);
  --spectrum-global-color-gray-75: rgb(250, 250, 250);
  --spectrum-global-color-gray-100: #131713;
  --spectrum-global-color-gray-200: rgb(234, 234, 234);
  --spectrum-global-color-gray-300: rgb(225, 225, 225);
  --spectrum-global-color-gray-400: rgb(202, 202, 202);
  --spectrum-global-color-gray-500: rgb(179, 179, 179);
  --spectrum-global-color-gray-600: rgb(142, 142, 142);
  --spectrum-global-color-gray-700: rgb(110, 110, 110);
  --spectrum-global-color-gray-800: #C7CFD9;
  --spectrum-global-color-gray-900: rgb(226, 217, 217);
  --spectrum-alias-background-color-modal-overlay: rgba(0,0,0,0.4);
  --spectrum-alias-dropshadow-color: rgba(0,0,0,0.15);
  --spectrum-alias-background-color-hover-overlay: rgba(44,44,44,0.04);
  --spectrum-alias-highlight-hover: rgba(44,44,44,0.06);
  --spectrum-alias-highlight-active: rgba(44,44,44,0.1);
  --spectrum-alias-highlight-selected: rgba(20,115,230,0.1);
  --spectrum-alias-highlight-selected-hover: rgba(20,115,230,0.2);
  --spectrum-alias-text-highlight-color: rgba(20,115,230,0.2);
  --spectrum-alias-background-color-quickactions: rgba(245,245,245,0.9);
  --spectrum-alias-radial-reaction-color-default: rgba(75,75,75,0.6);
  --spectrum-alias-pasteboard-background-color: var(--spectrum-global-color-gray-300);
  --spectrum-alias-appframe-border-color: var(--spectrum-global-color-gray-300);
  --spectrum-alias-appframe-separator-color: var(--spectrum-global-color-gray-300);
  --spectrum-colorarea-border-color: rgba(44,44,44,0.1);
  --spectrum-colorarea-border-color-hover: rgba(44,44,44,0.1);
  --spectrum-colorarea-border-color-down: rgba(44,44,44,0.1);
  --spectrum-colorarea-border-color-key-focus: rgba(44,44,44,0.1);
  --spectrum-colorslider-border-color: rgba(44,44,44,0.1);
  --spectrum-colorslider-border-color-hover: rgba(44,44,44,0.1);
  --spectrum-colorslider-border-color-down: rgba(44,44,44,0.1);
  --spectrum-colorslider-border-color-key-focus: rgba(44,44,44,0.1);
  --spectrum-colorslider-vertical-border-color: rgba(44,44,44,0.1);
  --spectrum-colorslider-vertical-border-color-hover: rgba(44,44,44,0.1);
  --spectrum-colorslider-vertical-border-color-down: rgba(44,44,44,0.1);
  --spectrum-colorslider-vertical-border-color-key-focus: rgba(44,44,44,0.1);
  --spectrum-colorwheel-border-color: rgba(44,44,44,0.1);
  --spectrum-colorwheel-border-color-hover: rgba(44,44,44,0.1);
  --spectrum-colorwheel-border-color-down: rgba(44,44,44,0.1);
  --spectrum-colorwheel-border-color-key-focus: rgba(44,44,44,0.1);
  --spectrum-miller-column-item-background-color-selected: rgba(20,115,230,0.1);
  --spectrum-miller-column-item-background-color-selected-hover: rgba(20,115,230,0.2);
  --spectrum-tabs-compact-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-compact-vertical-rule-color: var(--spectrum-global-color-gray-200);
  --spectrum-tabs-compact-vertical-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-compact-vertical-emphasized-rule-color: var(--spectrum-global-color-gray-200);
  --spectrum-tabs-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-quiet-compact-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-quiet-compact-vertical-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-quiet-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-quiet-vertical-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  /* --spectrum-well-background-color: #C7CFD9;
  --spectrum-well-border-color: rgba(44,44,44,0.05); */
  --spectrum-global-color-gray-100: #131713;
    --spectrum-global-color-gray-800: #3a3c3f;
    --spectrum-global-color-static-white:#038C7F;
    --spectrum-semantic-cta-color-background-default:#014017;

  --spectrum-tray-background-color: var(--spectrum-global-color-gray-50);
  --spectrum-semantic-cta-color-background-default:#014017;
}
