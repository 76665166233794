.threeDataDialBox{
    background-color: rgba(39, 38, 38,.6);
    border-radius: .75rem;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 30%), 0 15px 12px rgba(0, 0, 0, 22%);
    -webkit-backdrop-filter: blur(4rem);
    backdrop-filter: blur(4rem);

    h3, span{
        color:#fff;
    }
    ._spectrum-Dialog_30293._spectrum-Dialog--dismissable_30293 ._spectrum-Dialog-grid_30293{
        color:#fff;
    }
    ._spectrum-Field_06fc4{
        margin-left:1rem;
    }
    ._spectrum-Dropdown-trigger_06fc4{
         span._spectrum-Dropdown-label_06fc4{
            color:black;
        }
    }

    ._spectrum-StatusLight_71d0f{
        color:rgb(214, 211, 211);
    }
 
}

input[type=radio]{
    accent-color: black;
}
