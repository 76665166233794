.section{
    width: 100%;
}

.mdc-list{
    li{
        color:#c8cfd8;
        background-color: rgba(1, 64, 23, .1);
        border: #038C7F solid 1px;
        height: calc(48px - 2 * 4px);
        margin: 8px 8px;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 2rem .5rem;
        // &:hover{
        //     background-color: rgba(61,138, 127, .6);
        // }
        // &:focus{
        //     background-color: rgba(61,138, 127, .3);
        // }
    }
}    
.w-100{
    width:100%;
}

.mr{
    &.m1{
        margin-right: .25rem;
    }
    &.m2{
        margin-right: .5rem;
    }
    &.m3{
        margin-right: .75rem;
    }
    &.m4{
        margin-right: 1rem;
    }
}