@use '@rmwc/list/styles';

.mdc-drawer{
    &.t3dataNav{
        background:none;
        border: transparent;
        .mdc-drawer__title{
            .img-responsive{
                width: 100%;
                padding: 1rem 2.5rem;
                box-sizing: border-box;
            }
        }
        .mdc-drawer__content{
            .mdc-list{
                li{
                    color:#c8cfd8;
                    background-color: transparent;
                    font-size: medium;
                    
                    &:hover{
                        color: #04d8c4;
                        background-color: #262626;
                    }
                    &:focus{
                       color: #04d8c4;
                       background-color: #262626;
                    }
                }
                :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::after{
                    background-color: transparent;
                }
            }
        }
    }    
}

.sideNav{
    background-color: #171717;
    height: 100vh;
    float: left;
}

.innerSpace {
    padding: 0;
    background-color: #171717;
    display: inline-block;
    height: 100vh;
    width: 100%;
    overflow-y: scroll;
    float: right;
}

.menu-item-nav {
    border-radius: 5px;
    border: none;
    background-color: transparent;
}

.menu-item-nav:hover {
    background-color: #038C7F;
    color: white;
}

.menu-item {
    margin-left: auto;
    margin-right: auto;
}

#nav-dropdown {
    border: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-radius: 25px;
    color: white;
    cursor: pointer;
}