.fc-day-today {
    background-color:rgba(73, 255, 192, 0.2) !important;
}
  
.launch-button {
    color: #038C7F;
    background-color: transparent;
    border: transparent;
    cursor: pointer;
    padding-top: 10px;
    padding-bottom: 10px;
}
  
#no-launch {
    color: gray;
    cursor: not-allowed;
}

.launch-button-cont {
    background-color: #171717;
    text-align: center;
    border-radius: 10px;
}

.upcoming-meetings-card {
    width: '100%';
    border: none;
    padding-top: 5px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    background-image: linear-gradient(#171717, #232323);
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}