.cal-text {
    margin-bottom: 20px;
    border-color: transparent;
    border-width: 1px;
    border-radius: 10px;
    height: 25px;
    width: 60%;
    background-color: #171717;
    color: white;
}
.cal-text:active {
    border: none;
}

.event-main-container {
    margin: 30px;
}

#description {
    height: 50px;
    resize: none;
    font-family: Arial, Helvetica, sans-serif;
}

.subtext {
    font-size: small;
    font-style: italic;
}

.cal-datepicker {
    padding-left: 5px;
    background-color: #171717;
    color: white;
    margin-bottom: 20px;
    border-color: none;
    border-width: 0;
    border-radius: 10px;
    height: 25px;
    width: 100%;
}

.event-sub-container {
    width: 100%;
}
// style={{width: '100%', marginRight: 'auto', marginLeft: 'auto'}}
